/* Add to your CSS file */
.auth-header {
  background-color: #fff;
  height: 60px; /* Adjust height as needed */
  border-bottom: 1px solid var(--color-lightgrey);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.auth-logo {
    margin-left: 20px;
    max-width: 180px;
    /* Other styling as needed */
}
