/* Global styles */
html {
  scroll-behavior: smooth;
}

html, body {
    height: 100vh;
    margin: 0;
    padding: 0;
    color: var(--color-black); /* Default text color */
}

/* Base font settings for all elements */
body, button, input, select, textarea {
    font-weight: 400;
    font-size: 17px;
    line-height: 1.6;
    color: var(--color-black);
}

/* Custom palette */
:root {
    --color-primary: #3e3effe3;
    --color-secondary: #ff6a3ee3;
    --color-black: #000000e3;
    --color-dark: #1f1f1f;
    --color-darker: #121212;
    --color-darkgrey: #00000095;
    --color-grey: #00000065;
    --color-lightgrey: #00000025;
    --color-light: #EEEEEEE3;
}

/* Typography Fallback Styles */
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--color-black);
}

h1 {
    font-size: 3.53rem;
    line-height: 1.4em;
}

h2 {
    font-size: 2.35rem;
    line-height: 1.25em;
}

h3 {
    font-size: 1.88rem;
    line-height: 1.2em;
}

h4 {
    font-size: 1.41rem;
    line-height: 1.2em;
}

h5 {
    font-size: 1.18rem;
    line-height: 1.2em;
}

h6 {
    font-size: 1rem;
    line-height: 1.25em;
}
