/* GLOBAL OVERRIDES */
.grecaptcha-badge { 
  visibility: hidden;
}

/* Background color in grid layout drag and drop */
.react-grid-placeholder {
  background-color: #fff !important;
}

